@import url('https://fonts.googleapis.com/css?family=Poppins');
body{
    overflow-y: scroll;
    overflow-x: hidden;
}



:root, body, html{
box-sizing: border-box;
font-family: "Poppins", sans-serif;
margin: 0;
padding: 0;
background:#f5f5f5;
}

div, h1,h2,h3,h4,h5,header,html,img,i,label,li,nav,p,small,span,ul{
margin: 0;
padding: 0;
border: 0;
outline: none;
font-size: 100%;
vertical-align: baseline;
background: transparent;
font-family: "Poppins", sans-serif;


}

body{
font-family: Arial, Helvetica, sans-serif;
}



